import ApiHttp from '~/api/global/http/ApiHttp';
import type { IUser } from '~/store/user/user.types';
import type {
  ISetSteamDataRequestDTO,
  IUserData,
  TSetSteamDataResponseDTO,
} from '~/repository/modules/user/user.types';
import type { IAppRequest } from '~/types/Shared.types';

export default class UserHttp extends ApiHttp {
  private readonly userApi: string;
  private readonly userOffers: string;
  private readonly userSessions: string;
  private readonly setSteam: string;

  constructor() {
    super();
    this.userApi = '/v1/users/';
    this.userOffers = '/v1/offers/user/';
    this.userSessions = '/v1/users/sessions';
    this.setSteam = '/v1/users/set/steam/data';
  }

  public async getUser(userId: number): Promise<IAppRequest<IUserData>> {
    return await this.$authApi.get(this.userApi + userId);
  }

  public getUserOffers(userId: number) {
    return this.$authApi.get(this.userOffers + userId);
  }

  public postUserSession(url: string): Promise<void> {
    return this.$api.post(this.userSessions, {
      body: {
        sessionUrl: url,
      },
    });
  }

  public getMe() {
    return this.$authApi.get<{ data: IUser }>(this.userApi + 'me');
  }

  public setSteamData(params: ISetSteamDataRequestDTO): Promise<TSetSteamDataResponseDTO> {
    return this.$authApi.post(this.setSteam, {
      body: {
        ...params,
      },
    });
  }

  public generateLevel() {
    return this.$authApi.post(this.userApi + 'level/generate');
  }
}
